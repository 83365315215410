<template>
  <b-row>
    <b-col :cols="6">
      <b-form-group
        id="item-quantity-group"
        v-bind:label="$t('FORM_LABELS.QTY')"
        label-for="item-quantity-input"
      >
        <FormInput
          type="number"
          :i18n="{
            label: 'FORM_LABELS.QTY',
            placeholder: $t('FORM_LABELS.QTY')
          }"
          v-model="expense.quantity"
          :validateState="{}"
          @input="$emit('update:qty')"
        ></FormInput>
      </b-form-group>
    </b-col>
    <b-col :md="6">
      <b-form-group
        id="item-machines-group"
        :label="$t('FORM_LABELS.MACHINE')"
        label-for="item-machines-input"
      >
        <FormSelect
          clearable
          type="select"
          :options="machines"
          v-model="expense.machine"
        ></FormSelect>
      </b-form-group>
    </b-col>

    <b-col :md="6">
      <b-form-group
        id="item-serviceWarehouseItems-group"
        :label="$t('FORM_LABELS.WAREHOUSE_ITEM')"
        label-for="item-serviceWarehouseItems-input"
      >
        <FormSelect
          clearable
          type="select"
          :options="serviceWarehouseItems"
          v-model="expense.serviceWarehouseItemId"
        ></FormSelect>
      </b-form-group>
    </b-col>

    <b-col :md="6">
      <b-form-group
        id="item-staff-group"
        :label="$t('MENU.ITEM.STAFF.STAFF')"
        label-for="item-staff-input"
      >
        <FormSelect
          clearable
          type="select"
          :options="selectStaffList"
          v-model="expense.staffId"
        ></FormSelect>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import FormInput from "@/view/content/forms/components/FormInput.vue";
import FormSelect from "@/view/content/forms/components/FormSelect";

export default {
  name: "FuelExpensesRowCharge",
  components: {
    FormSelect,
    FormInput
  },
  props: {
    canRemove: Boolean,
    expense: Object,
    staff: Array,
    machines: Array,
    serviceWarehouseItems: Array
  },
  data() {
    return {
      tempStaffList: null
    };
  },
  computed: {
    selectStaffList() {
      return this.tempStaffList || this.staff;
    }
  },
  watch: {
    "expense.machine": {
      immediate: true,
      handler(value) {
        if (!value) {
          return;
        }
        this.expense.machineId = value.id;
        this.tempStaffList = null;
        this.expense.staffId = value.lastUsedStaffId || null;
        this.expense.serviceWarehouseItemId = null;
      }
    },
    "expense.serviceWarehouseItemId": {
      immediate: true,
      handler(value) {
        if (!value) {
          return;
        }
        this.expense.machine = null;
        this.expense.machineId = null;
      }
    }
  }
};
</script>

<style></style>
