<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Add new'">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link
            :to="{
              name: 'edit-service-invoices',
              params: { id: $route.params.invoiceId }
            }"
          >
            {{ serviceInvoiceName }}
          </router-link>
          <i class="mr-2"></i>
          <small class="">{{ $t("LABELS.ADD_NEW") }}</small>
        </h3>
      </template>
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingServiceInvoiceRow"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
      <template v-slot:body>
        <ServiceInvoiceRowsForm
          ref="ServiceInvoiceRowsForm"
          :form="form"
        ></ServiceInvoiceRowsForm>

        <v-overlay :value="!firstLoader && isLoadingServiceInvoiceRow">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingServiceInvoiceRow"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SAVE_SERVICE_INVOICE_ROW } from "@/modules/service-invoice-rows/store/service-invoice-rows.module";
import { FETCH_SERVICE_INVOICE } from "@/modules/service-invoices/store/service-invoices.module";

import SaveButtonDropdown from "@/view/content/forms/components/SaveButtonDropdown.vue";

import KTCard from "@/view/content/Card.vue";
import ServiceInvoiceRowsForm from "../components/ServiceInvoiceRowsForm.vue";
import { mapGetters } from "vuex";

export default {
  name: "ServiceInvoiceRowsAdd",
  components: {
    KTCard,
    SaveButtonDropdown,
    ServiceInvoiceRowsForm
  },
  data() {
    return {
      errors: {},
      serviceInvoice: {},
      form: {
        serviceInvoiceId: null,
        name: null,
        code: null,
        price: null,
        totalPrice: 0,
        totalQty: 0,
        serviceExpenses: [
          {
            machineId: null,
            serviceWarehouseItemId: null,
            staffId: null,
            quantity: null
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters(["isLoadingServiceInvoiceRow"]),
    serviceInvoiceName() {
      return (
        this.serviceInvoice.invoiceNumber +
        " " +
        this.serviceInvoice.serviceVendor
      );
    }
  },
  mounted() {
    this.fetchServiceInvoice();
  },
  methods: {
    fetchServiceInvoice() {
      this.$store
        .dispatch(FETCH_SERVICE_INVOICE, this.$route.params.invoiceId)
        .then(data => {
          this.serviceInvoice = data.data;

          this.$store.dispatch(SET_BREADCRUMB, [
            {
              title: this.$i18n.t("MENU.ITEM.SERVICE.INVOICES"),
              route: { name: "list-service-invoices" }
            },
            {
              title: this.serviceInvoiceName,
              route: {
                name: "edit-service-invoices",
                params: { id: this.$route.params.invoiceId }
              }
            },
            { title: this.$i18n.t("LABELS.ADD_NEW") }
          ]);
        });
    },
    onSave(next) {
      let vm = this;
      this.$refs.ServiceInvoiceRowsForm.$v.form.$touch();
      if (this.$refs.ServiceInvoiceRowsForm.$v.form.$anyError) {
        vm.$notify({
          group: "notify",
          type: "error",
          title: "<i class='flaticon-close'></i> Warning",
          text: "Please fill all required fields correctly"
        });
        return;
      }

      this.form.serviceInvoiceId = this.$route.params.invoiceId;
      this.$store
        .dispatch(SAVE_SERVICE_INVOICE_ROW, this.form)
        .then(data => {
          vm.errors = data.data.errors;
          let itemEdit = data.data;
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          // let itemEdit = data.data;
          if (next == "continue") {
            //New
            this.$router.push({
              name: "edit-service-invoice-rows",
              params: { id: itemEdit.id }
            });
          }
          if (next == "new") {
            //New
            this.$router.push({ name: "add-service-invoice-rows" });
          }
          if (next == "exit") {
            this.$router.push({
              name: "edit-service-invoices",
              params: { id: this.form.serviceInvoiceId }
            });
          }
        })
        .catch(response => {
          this.errors = this.$errors.format(response.data.errors || {});
          this.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response.data.message
          });
        });
    }
  }
};
</script>
