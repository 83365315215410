<template>
  <v-card flat>
    <b-row>
      <b-col :md="4">
        <b-form-group
          id="item-name-group"
          v-bind:label="$t('FORM_LABELS.NAME')"
          label-for="item-name-input"
        >
          <FormInput
            type="text"
            :i18n="{
              label: 'FORM_LABELS.NAME',
              placeholder: $t('FORM_LABELS.NAME')
            }"
            v-model="form.name"
          ></FormInput>
        </b-form-group>
      </b-col>
      <b-col :md="4">
        <b-form-group
          id="item-code-group"
          v-bind:label="$t('FORM_LABELS.CODE')"
          label-for="item-code-input"
        >
          <FormInput
            type="text"
            :i18n="{
              label: 'FORM_LABELS.CODE',
              placeholder: $t('FORM_LABELS.CODE')
            }"
            v-model="form.code"
          ></FormInput>
        </b-form-group>
      </b-col>
      <b-col :md="4">
        <b-form-group
          id="item-price-group"
          v-bind:label="$t('FORM_LABELS.PRICE')"
          label-for="item-price-input"
        >
          <FormInput
            type="number"
            :i18n="{
              label: 'FORM_LABELS.PRICE',
              placeholder: $t('FORM_LABELS.PRICE')
            }"
            v-model="form.price"
            :validations="'validations: {required: true, minLength: 0}'"
            :validateState="validateState('price')"
            @input="totalSum"
          ></FormInput>
        </b-form-group>
      </b-col>
    </b-row>
    <ServiceInvoiceRowsExpense
      v-for="(item, i) in form.serviceExpenses"
      :key="i"
      :expense="item"
      :staff="staffList"
      :machines="machines"
      :serviceWarehouseItems="serviceWarehouseItems"
      :can-remove="false"
      @remove="handleRemove(i)"
      @update:qty="handleUpdateQty"
    >
    </ServiceInvoiceRowsExpense>
    <b-row>
      <div class="col-md-8 d-flex align-center">
        <div v-if="form.serviceExpenses.length > 0" class="text-left">
          <h5>
            <span class="text-success">
              Обща сума: <b>{{ form.totalPrice.toFixed(4) }} лв.</b>
            </span>
          </h5>
        </div>
      </div>
    </b-row>
  </v-card>
</template>

<script>
import { FETCH_MACHINES } from "@/modules/machines/store/machine.module";
import { FETCH_ALL_STAFF } from "@/modules/staff/store/staff.module";
import { FETCH_SERVICE_WAREHOUSE_ITEMS } from "@/modules/service-warehouse-items/store/service-warehouse-items.module";

import FormInput from "@/view/content/forms/components/FormInput.vue";
import FormDatepicker from "@/view/content/forms/components/FormDatepicker.vue";
import FormSelect from "@/view/content/forms/components/FormSelect";
import FormSwitch from "@/view/content/forms/components/FormSwitch.vue";
import ServiceInvoiceRowsExpense from "./ServiceInvoiceRowsExpense";

import { validationMixin } from "vuelidate";
import { formBuilderMixin } from "@/core/mixins/formBuilderMixin.js";

export default {
  name: "ServiceInvoicesForm",
  mixins: [validationMixin, formBuilderMixin],
  components: {
    FormSelect,
    FormInput,
    FormSwitch,
    FormDatepicker,
    ServiceInvoiceRowsExpense
  },
  props: {
    form: Object,
    serviceInvoice: Object
  },
  validations() {
    const tmpValidationObject = {
      form: {
        price: this.setItemValidations({
          required: true
        })
      }
    };

    return tmpValidationObject;
  },
  data() {
    return {
      serviceExpensesResults: [],
      serviceExpensesLoading: false,
      options: [],
      machines: [],
      serviceWarehouseItems: [],
      staffList: []
    };
  },
  mounted() {
    this.$store
      .dispatch(FETCH_MACHINES, this.$url.transformParams({ active: 1 }))
      .then(data => {
        this.machines = data.data.items.map(v => {
          return {
            id: {
              id: v.id,
              name: v.name,
              lastUsedStaffId: v.lastUsedStaffId
            },
            name: v.name
          };
        });
      });

    this.$store.dispatch(FETCH_ALL_STAFF, "?active=1").then(data => {
      let items = data.data.items;

      this.staffList = items || [];
    });

    this.$store.dispatch(FETCH_SERVICE_WAREHOUSE_ITEMS).then(data => {
      this.serviceWarehouseItems = data.data.items.map(i => {
        return {
          id: i.id,
          name: i.name + " | " + i.warehouse
        };
      });
    });
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    totalSum() {
      const total = parseFloat(this.form.price) * this.form.totalQty || 0;

      this.form.totalPrice = total;
    },
    handleUpdateQty() {
      this.form.totalQty = 0;
      this.form.serviceExpenses.forEach(tc => {
        this.form.totalQty += parseFloat(tc.quantity || 0);
      });

      this.totalSum();
    },
    handleRemove(i) {
      this.form.serviceExpenses.splice(i, 1);
      this.handleUpdateQty();
    },
    handleAddExpense() {
      const lastExpense = {
        date: null,
        machineId: null,
        serviceWarehouseItemId: null,
        staffId: null,
        quantity: null
      };
      this.form.serviceExpenses.push(lastExpense);
      this.handleUpdateQty();
    }
  }
};
</script>

<style type="text/css">
.input-lg input {
  font-size: 14px;
  font-weight: bold;
}
.vs__dropdown-toggle {
  background-color: #fff;
}
#item-bol-list-group label {
  font-weight: bold;
  font-size: 15px;
}

.sort_div_holder {
  position: relative;
}
.sort_div {
  display: inline-block;
  padding: 16px 10px;
}
.sort_div_filter {
  position: absolute;
  top: 8px;
  right: -34px;
}

.v-list--dense .v-list-item,
.v-list-item--dense {
  min-height: 20px !important;
  height: 2rem;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 0.5rem !important;
}

.v-list-item--link {
  transition: background-color 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.v-list-item--link:hover {
  background-color: rgba(0, 0, 0, 0.13);
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 13px !important;
}

.v-data-table > .v-data-table__wrapper > table > thead > th.sortable {
  position: relative;
}
.theme--light.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
}
.v-data-table-header__icon {
  height: 1px !important;
  float: right !important;
  top: -27px !important;
  right: -3px;
}
@media (min-width: 1264px) {
  .container {
    max-width: 100% !important;
  }
}

.service-section {
  border: 1px solid #ecf0f3;
  margin-bottom: 5px !important;
  border-radius: 0.42rem;
  -webkit-box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
}
</style>
