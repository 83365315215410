var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('b-row',[_c('b-col',{attrs:{"md":4}},[_c('b-form-group',{attrs:{"id":"item-name-group","label":_vm.$t('FORM_LABELS.NAME'),"label-for":"item-name-input"}},[_c('FormInput',{attrs:{"type":"text","i18n":{
            label: 'FORM_LABELS.NAME',
            placeholder: _vm.$t('FORM_LABELS.NAME')
          }},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)],1),_c('b-col',{attrs:{"md":4}},[_c('b-form-group',{attrs:{"id":"item-code-group","label":_vm.$t('FORM_LABELS.CODE'),"label-for":"item-code-input"}},[_c('FormInput',{attrs:{"type":"text","i18n":{
            label: 'FORM_LABELS.CODE',
            placeholder: _vm.$t('FORM_LABELS.CODE')
          }},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}})],1)],1),_c('b-col',{attrs:{"md":4}},[_c('b-form-group',{attrs:{"id":"item-price-group","label":_vm.$t('FORM_LABELS.PRICE'),"label-for":"item-price-input"}},[_c('FormInput',{attrs:{"type":"number","i18n":{
            label: 'FORM_LABELS.PRICE',
            placeholder: _vm.$t('FORM_LABELS.PRICE')
          },"validations":'validations: {required: true, minLength: 0}',"validateState":_vm.validateState('price')},on:{"input":_vm.totalSum},model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", $$v)},expression:"form.price"}})],1)],1)],1),_vm._l((_vm.form.serviceExpenses),function(item,i){return _c('ServiceInvoiceRowsExpense',{key:i,attrs:{"expense":item,"staff":_vm.staffList,"machines":_vm.machines,"serviceWarehouseItems":_vm.serviceWarehouseItems,"can-remove":false},on:{"remove":function($event){return _vm.handleRemove(i)},"update:qty":_vm.handleUpdateQty}})}),_c('b-row',[_c('div',{staticClass:"col-md-8 d-flex align-center"},[(_vm.form.serviceExpenses.length > 0)?_c('div',{staticClass:"text-left"},[_c('h5',[_c('span',{staticClass:"text-success"},[_vm._v(" Обща сума: "),_c('b',[_vm._v(_vm._s(_vm.form.totalPrice.toFixed(4))+" лв.")])])])]):_vm._e()])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }